@import "@videogular/ngx-videogular/fonts/videogular.css";

@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: InterVariable;
    font-style: normal;
    font-weight: 100 900;
    font-display: swap;
    src: url("/font/inter/InterVariable.woff2") format("woff2");
}
@font-face {
    font-family: InterVariable;
    font-style: italic;
    font-weight: 100 900;
    font-display: swap;
    src: url("/font/inter/InterVariable-Italic.woff2") format("woff2");
}
